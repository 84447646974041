


































































import { TableRowTs } from './TableRowTs';

export default class TableRow extends TableRowTs {}
